<template>
  <div>
    <operations-detail-component-global 
      :sApiGetOC="sApiGetOC" 
      :aHeadersRawMaterials="aHeadersRawMaterials"
      :sTypeItem="3" 
      sBackHref="OPPayable" 
      sTextDetail="" 
      setDialog="setDialogConfirmationOfPaid"
      sTextBtnAdd="Marcar como finalizada" 
      sClassBtnAdd="global-btn-header-primary " 
      :bAdminPermissions="bAdminOPPayable"
      :toProvider="toProvider" 
      :bToProvider="true" 
      :itemsBreadcrumbs="itemsBreadcrumbs" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
      toProvider: "",
      sApiGetOC: "",
      aHeadersRawMaterials: [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre de materia",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Merma",
          sortable: false,
          value: "dUnprocessedWeight",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Precio unitario",
          sortable: false,
          value: "dUnitPriceRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Subtotal",
          sortable: false,
          value: "dSubtotalRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre del responsable",
          sortable: false,
          value: "sManagerRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.setApiGetOC();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Operaciones de caja",
          disabled: false,
          to: { name: "OPPayable" },
        },
        {
          text: "Detalle de una operación de caja",
          link: true,
          exact: true,
          disabled: true,
          to: { name: "OPPayableDetail" },
          params: { id: this.$route.params.idOP },
        },
      ];
      this.itemsBreadcrumbs = aBreadcrumbs;
      this.toProvider = `/home/op-payable-detail/${this.$route.params.idOP}/provider-detail-op-payable`;
    },
    setApiGetOC() {
      switch (this.sTabPositionOrderPayable) {
        case 0:
          this.sApiGetOC = `${URI}/api/sp/v1/orders/cash-register/pending/`;
          // this.$store.commit("refresher", true);
          break;
        case 1:
          this.sApiGetOC = `${URI}/api/sp/v1/orders/cash-register/payed/`;
          // this.$store.commit("refresher", true);
          break;

        default:
          break;
      }
      // this.sApiGetOC = `${URI}/api/sp/v1/orders/cash-register/`;
    },
  },
  computed: {
    sTabPositionOrderPayable() {

      return this.$store.state.sTabPositionOrderPayable

    },
    bShowOPPayable() {
      return this.$store.state.bShowOPPayable;
    },
    bAdminOPPayable() {
      return this.$store.state.bAdminOPPayable;
    },

  },
};
</script>

<style>
</style>